<template>
    <div
        v-if="useStore().isNoStore() && status && status !== ''"
        :class="{
            'status-container': true,
            'tile-item-view': tileView,
            'only-circle': !showText,
        }">
        <div :class="['status-indicator', circleClass]" />
        <span v-if="showText" :class="textClass">{{ statusText }}</span>
    </div>
</template>
<script setup lang="ts">
import useMatrixDateHelper from '~/composables/matrix/useMatrixDateHelper';
import useDateFormat from '~/composables/utils/useDateFormat';
import useStore from '~/composables/service/useStore';

const props = withDefaults(defineProps<{
    status?: string | null,
    reAvailabilityDate?: string | null,
    tileView?: boolean,
    showText?: boolean,
}>(), {
    status: '',
    reAvailabilityDate: '',
    tileView: false,
    showText: true,
});

const { $t } = useNuxtApp();
const reAvailability = computed(
    () => props.reAvailabilityDate && props.reAvailabilityDate !== '' && props.reAvailabilityDate > useMatrixDateHelper().dateToString()
);
const circleClass = computed(() => props.status ? `circle-${props.status}` : '');
const textClass = computed(() => props.status ? `text-${props.status}` : '');
const statusText = computed(() => {
    switch (props.status) {
    case 'green':
        return $t('Product.immediately_available');
    case 'yellow':
        if (!reAvailability.value) {
            return $t('Product.low_inventory');
        }

        return `${$t('Product.expected_more_available_from')} ${useDateFormat(new Date(props.reAvailabilityDate ?? ''), 'DD.MM.YYYY')}`;
    case 'red':
        if (!reAvailability.value) {
            return $t('Product.not_available');
        }

        return `${$t('Product.available_again', [useDateFormat(new Date(props.reAvailabilityDate ?? ''), 'DD.MM.YYYY')])}`;
    default:
        return '';
    }
});

</script>
<style lang="scss" scoped>
.status-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(6);
    padding-left: sp(xs);
}

.status-indicator {
    width: rem(10);
    height: rem(10);
    border-radius: 50%;
    flex-shrink: 0;
}

span {
    font-weight: bold;
}

.circle-green {
    @include helper-color-bg(alert-success);
}

.circle-yellow {
    @include helper-color-bg(alert-warning);
}

.circle-red {
    @include helper-color-bg(bright-red);
}

.text-green {
    @include helper-color(alert-success);
}

.text-yellow {
    @include helper-color(alert-warning);
}

.text-red {
    @include helper-color(bright-red);
}

.tile-item-view {
    justify-content: flex-start;
    margin: 0;
    padding: 0;
}

.only-circle {
    padding: 0;
}
</style>
