<template>
    <span v-if="productAbstractRelations?.predecessors" class="product-relations">
        {{ $t('Product.model_number_predecessor') }}
        <template v-for="(predecessor, index) in productAbstractRelations?.predecessors" :key="`predecessor-${index}`">
            <span :title="`${$t('Product.model_number_predecessor_title')} ${predecessor.model_no_frontend ?? ''}`"/>
            <span
                v-if="predecessor.active"
                class="product-relation product-relation--link"
                @click="e => redirectToPdp(e, predecessor.sku)"
            >
                {{ predecessor.model_no_frontend ?? '' }}
            </span>
            <span
                v-else
                class="product-relation"
            >
                {{ predecessor.model_no_frontend ?? '' }}
            </span>
        </template>
    </span>
    <span v-if="productAbstractRelations?.successors" class="product-relations" :title="$t('Product.model_number_successor_title')">
        {{ $t('Product.model_number_successor') }}
        <template v-for="(successor, index) in productAbstractRelations?.successors" :key="`successor-${index}`">
            <span :title="`${$t('Product.model_number_successor_title')} ${successor.model_no_frontend ?? ''}`"/>
            <span
                v-if="successor.active"
                class="product-relation product-relation--link"
                @click="e => redirectToPdp(e, successor.sku)"
            >
                {{ successor.model_no_frontend ?? '' }}
            </span>
            <span
                v-else
                class="product-relation"
            >
                {{ successor.model_no_frontend ?? '' }}
            </span>
        </template>
    </span>
</template>

<script lang="ts" setup>
import { NuxtLinkNameTypes } from '~/composables/types/nuxtHyperlinkTypes';
import type { ProductAbstractRelations } from '~/composables/types/api/searchDiscover/catalogSearch';

const router = useRouter();

defineProps<{
    productAbstractRelations: ProductAbstractRelations | null,
}>();
const redirectToPdp = (e: MouseEvent, sku: string) => {
    const link = `${NuxtLinkNameTypes.PRODUCT}/${sku}`;

    if (e.ctrlKey || e.metaKey) {
        window.open(link, '_blank');

        return;
    }

    router.push(link);
};
</script>

<style lang='scss' scoped>
.product-relations {
    padding-left: sp(xs2);

    @include helper-color(text-secondary);

    &::before {
        margin-left: -#{sp(xs)};
        position: absolute;
        content: '|';
    }

    .product-relation {
        &:not(:last-child) {
            padding-right: sp(xs);
            margin-right: sp(xxs);

            &::after {
                margin-left: sp(xxs);
                position: absolute;
                content: '|';
            }
        }

        &--link {
            cursor: pointer;

            @include helper-color(corporate-blue);
        }
    }
}
</style>
