<template>
    <div :class="{'toggle': true, active: isChecked, unset: isChecked === undefined, disabled: disabled}" @click="!disabled ? onClick() : undefined">
        <div class="toggle__background" />
        <div class="toggle__handle" />
        <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{
    errorMessage?: string,
    modelValue?: boolean,
    disabled?: boolean
}>(), {
    errorMessage: undefined,
    modelValue: undefined,
    disabled: undefined
});

const emit = defineEmits<{
    (e: 'update:modelValue', modelValue: boolean | undefined): void
}>();

const isChecked: Ref<boolean | undefined> = computed({
    get() {
        return Boolean(props.modelValue);
    },
    set(value) {
        emit('update:modelValue', value);
    }
});

const onClick = () => {
    isChecked.value = !isChecked.value;
};
</script>

<style lang="scss" scoped>
.toggle {
    position: relative;
    width: rem(48);
    height: rem(24);
    cursor: pointer;

    &__background {
        position: absolute;
        border-radius: rem(25);
        left: rem(4);
        width: rem(42);
        height: rem(24);
        transition: background-color 0.3s, opacity 0.3s;
        display: inline-block;

        @include helper-color-bg(white);
        @include helper-border($setting-color-corporate-blue, rem(1));
    }

    &__handle {
        position: absolute;
        left: rem(8);
        top: rem(3);
        width: rem(18);
        height: rem(18);
        transition: background-color 0.3s, left 0.3s;
        border-radius: rem(25);

        @include helper-color-bg(corporate-blue);
    }

    .error-message {
        position: absolute;
        margin-top: rem(45);
        width: rem(200);

        @include helper-color(alert-danger);
        @include helper-font-size(smaller);
        @include helper-font-line-height(tight);
    }

    &.active {
        .toggle__handle {
            left: rem(24);

            @include helper-color-bg(white);
        }

        .toggle__background {
            @include helper-color-bg(corporate-blue);
        }
    }

    &.disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }

    &.unset {
        .toggle__handle {
            left: rem(16);

            @include helper-color-bg(corporate-blue);
        }
    }
}
</style>
