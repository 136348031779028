<template>
    <div class="options__container">
        <span class="spacing-right--xs">{{ $t('Matrix.maintain') }}</span>
        <AtomRadioButton
            name="quick-selection"
            :model-value="activeIndex"
            size="s"
            :items="[
                    { name: $t('Matrix.default_stock') },
                    { name: $t('Matrix.minimal_quantity') },
                ]"
            @update:model-value="emit('update:quickSelection', $event )"
        />
    </div>
</template>

<script lang="ts" setup>
defineProps<{
    activeIndex: number
}>();

const emit = defineEmits<{
    (e: 'update:quickSelection', index: number): void,
}>();
</script>

<style lang="scss" scoped>
    .options__container {
        display: flex;
        align-items: center;
    }
</style>
