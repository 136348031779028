import type { ApiResponse } from '~/composables/types/api/apiResponse';

export enum EIncludeTypes {
    CONCRETE_PRODUCTS = 'concrete-products',
    ABSTRACT_PRODUCTS = 'abstract-products',
    CONCRETE_PRODUCT_IMAGE_SETS = 'concrete-product-image-sets',
    CONCRETE_PRODUCT_PRICES = 'concrete-product-prices',
    ABSTRACT_PRODUCT_PRICES = 'abstract-product-prices',
    CONCRETE_PRODUCT_AVAILABILITIES = 'concrete-product-availabilities',
    ABSTRACT_PRODUCT_AVAILABILITIES = 'abstract-product-availabilities',
    // TODO: needs to be changed to abstract-product-relations in BE
    ABSTRACT_PRODUCT_RELATIONS = 'abstract-product-doubles',
    PRODUCT_LABELS = 'product-labels',
    PRODUCT_REVIEWS = 'product-reviews',
    CATEGORY_NODES = 'category-nodes',
}

export type AbstractCategoryInterface = {
    id: string;
    name: string;
    url: string;
    active: boolean;
    children: AbstractCategoryInterface[];
    parents: AbstractCategoryInterface[];
};

type AbstractProductOtherSuppliers = {
    abstractId: number;
    abstractSku: string;
    supplierGln: string;
    supplierName: string;
};

export type ItemsListQuantity = {
    qty: number;
    sizeKey: string;
    colorKey: string;
};

export type Include<A = any, R = any, T = string> = {
    id: string;
    type: T;
    attributes: A;
    relationships?: R;
    links?: object;
};

export type Category = {
    nodeId: number;
    order: number;
    name: string;
    children: Category[];
    metaTitle?: string;
    metaKeywords?: string;
    metaDescription?: string;
    docCount?: number;
    url?: string;
};

export type ProductVariant = {
    url: string;
    sku: string;
    name: string;
    gtin: string;
    superAttributesDefinition?: string[];
    superAttributes?: Record<string, string[]>;
    metaTitle: string;
    metaKeywords: string;
    metaDescription: string;
    averageRating: number | null;
    attributeMap?: any;
    attributeNames?: {
        [key: string]: string;
    };
    attributes?: {
        [key: string]: string;
    };
    description?: string;
    reviewCount?: number;
};

export type ProductLabel = {
    value: string;
    frontEndReference: string;
};

export type ProductReview = {
    rating: number;
    nickname: string;
    description: string;
    summary: string;
};

export type ProductPrice = {
    price_data_by_price_type: any;
    grossAmount?: number;
    netAmount?: number;
    priceTypeName: string;
    pricesObject: object;
    prices: object[];
    currency: {
        code: string;
        name: string;
        symbol: string;
    };
    scalePriceList: Record<number, string>;
};

export type RelationData = {
    type: string;
    id: string;
};

export type ProductRelations = {
    [key: string]: {
        data: RelationData[];
    };
};

export type ProductInclude = Include<ProductVariant, ProductRelations>;

export type ProductImages = {
    externalUrlLarge: string;
    externalUrlSmall: string;
};

type FilterId = {
    [id: string]: string;
};

export type Product = ProductVariant & {
    concreteImageSets?: FilterId;
    type: string;
    stock?: number;
    images?: ProductImages[];
    price?: number;
    prices?: ProductPrice;
    reviews?: ProductReview[];
    labels?: ProductLabel[];
    categoryNodes?: Category[];
    concreteProducts?: Product[];
    concreteProductPrices?: Record<string, ProductPrice[]>;
    abstractProductRelations?: object;
    concreteProductAvailabilities?: ConcreteProductAvailabilities[],
    gtin: string;
};

export type ProductReferenceItem = ApiResponse & {
    colors: string[];
    image: string;
    modelNo: string;
    modelName: string;
    quantities: Array<{
        qty: number;
        sizeKey: string;
        colorKey: string;
    }>;
    sizes: string[];
    totalItems: number;
    variantCount: number;
};

export type ReferenceItemData = ApiResponse & {
    type: string;
    id: string | number;
    attributes: {
        items: Array<ProductReferenceItem>;
    };
};

export type ReferenceItemsResponse = {
    data: Array<ReferenceItemData>;
};

export type ReferenceItemQuantity = {
    qty: number;
    sizeKey: string;
    colorKey: string;
};

type AbstractProductSuperAttributes = {
    attr_colorNo: string[];
    attr_size: string[];
};

type RelatedProductColorLots = {
    productName: string;
    abstractSku: string;
    abstractId: number;
};

export type ProductImageType = {
    colorName: string;
    colorNameFrontend: string;
    smallImage: string;
    largeImage: string;
};

export type ColorImageMappings = {
    color_name: string;
    color_name_frontend: string;
    gtin: string;
    image_url: string;
    is_default: boolean;
};

export type ConcreteProductAvailabilities = {
    availability: boolean;
    availableQuantity: number;
    id: string;
    isNeverOutOfStock: boolean;
    quantity: string;
    reAvailabilityDate: string | null;
    stockStatus: string;
    type: string;
}

export type AbstractProductsResult = ApiResponse & {
    type: string;
    id: string;
    name: string;
    brand: string;
    categories: AbstractCategoryInterface[] | null;
    description: string;
    supplierName: string;
    modelNumber: string;
    purchasePrice: string;
    sellPrice: string;
    purchasePriceOriginal: string;
    sellPriceOriginal: string;
    customerGroupAccess: boolean;
    labels: string[];
    images: Array<{
        small: string;
        large: string;
    }>;
    concreteProducts: Product[];
    concreteProductPrices: Record<string, ProductPrice[]>;
    concreteProductAvailabilities: ConcreteProductAvailabilities[],
    otherSuppliers: AbstractProductOtherSuppliers[];
    superAttributes: AbstractProductSuperAttributes;
    productColorLots: RelatedProductColorLots[];
    colorImageMappings: ColorImageMappings;
};

export type NotesIds = {
    idsWithNotes: Array<number>;
};

export type Notes = {
    type: string;
    id: string | null;
    attributes: NotesIds;
};

export type Note = {
    note: string;
    lastModified: string;
    lastEditor: string;
    isSuccessful: boolean;
};

export type ProductNoteData = {
    idProductAbstract: number;
    lastEditor: string;
    lastModified: string;
    note: string;
    _timestamp: string;
};

export type NoteData = ApiResponse & {
    type: string;
    id: string | null;
    attributes: Note;
};

export type NotesResponse = {
    data: Array<Notes>;
};

export type NoteResponse = {
    data: NoteData;
};

export type NoteUpdateParams = {
    note: string;
    noteId: string;
};
